import { floorNumber } from './number'

export const getLocale = () => {
  if (navigator == null) return null
  return navigator.languages?.length ? navigator.languages[0] : navigator.language
}

export const getIntlSeparator = (separatorType: 'decimal' | 'group') => {
  const numberWithGroupAndDecimalSeparator = 1000.1
  return Intl.NumberFormat(getLocale())
    ?.formatToParts(numberWithGroupAndDecimalSeparator)
    ?.find((part) => part.type === separatorType)?.value
}

export const getIntlCurrencySlot = (): 'start' | 'end' => {
  const numberWithCurrencySymbol = getIntlAccountingNumberFormat(1234, 'EUR')
  if (numberWithCurrencySymbol.indexOf('€') === 0 || numberWithCurrencySymbol.indexOf('EUR') === 0) return 'start'
  else return 'end'
}

export const getIntlDateFormat = (date: Date, options?: Intl.DateTimeFormatOptions) => {
  if (date instanceof Date && !isNaN(date as any)) {
    return new Intl.DateTimeFormat(getLocale(), options).format(date)
  } else return ''
}

export const getIntlDateShortFormat = (date: Date) => {
  return getIntlDateFormat(date, { dateStyle: 'short' })
}

export const getIntlTimeShortFormat = (date: Date) => {
  return getIntlDateFormat(date, { timeStyle: 'short' })
}

export const getIntlDateWithTimeFormat = (date: Date) => {
  return getIntlDateFormat(date, { dateStyle: 'short', timeStyle: 'short' })
}

export const getIntlNumberFormat = (num: number, options?: Intl.NumberFormatOptions) => {
  if (isNaN(num)) num = 0
  return new Intl.NumberFormat(getLocale(), options).format(num)
}

export const parseIntlNumberFormat = (numString: string): number => {
  if (typeof numString !== 'string' || numString == null) return numString as any

  const groupSeparator = getIntlSeparator('group')
  const decimalSeparator = getIntlSeparator('decimal')

  numString = numString.replace(new RegExp(`\\${groupSeparator}`, 'g'), '')

  if (decimalSeparator !== '.') numString = numString.replace(decimalSeparator, '.')

  return parseFloat(numString)
}

export const getIntlDecimalNumberFormat = (num: number, decimalPlaces = 2) => {
  return getIntlNumberFormat(num, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces })
}

export const getIntlAccountingNumberFormat = (num: number, currencyShortSign: string, decimalPlaces: number = 2) => {
  num = floorNumber(num, decimalPlaces)
  if (currencyShortSign != null && currencyShortSign != '') {
    try {
      return getIntlNumberFormat(num, {
        style: 'currency',
        currency: currencyShortSign,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      })
    } catch (error) {
      return getIntlDecimalNumberFormat(num)
    }
  } else return getIntlDecimalNumberFormat(num)
}

export const getIntlDecimalOddNumberFormat = (oddValue: number, maximumFractionDigits: number = 3) => {
  return getIntlNumberFormat(oddValue, {
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits
  })
}

export const getIntlPotentialWinningOddNumberFormat = (oddValue: number) => {
  return getIntlNumberFormat(oddValue, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
}

export const getIntlAmericanOddNumberFormat = (oddValue: number) => {
  return getIntlNumberFormat(oddValue, { minimumFractionDigits: 0, maximumFractionDigits: 0, signDisplay: "always" })
}
