import { Translate } from 'next-translate'
import { getIntlDateShortFormat, getIntlDateWithTimeFormat, getIntlTimeShortFormat } from './internationalization'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const utcOffset = parseInt(process.env.NEXT_PUBLIC_SERVERTIME_UTC_OFFSET_MINUTES)

const buildOffsetString = () => {
  const pad = (value) => (value < 10 ? '0' + value : value)
  const sign = utcOffset > 0 ? '+' : '-'
  const hours = pad(Math.floor(utcOffset / 60))
  const minutes = pad(utcOffset % 60)
  return sign + hours + ':' + minutes
}

export const getUtcDateString = (_date: Date) => {
  const date = _date + buildOffsetString()
  return date
}

export const getIsoDateString = (_date: Date) => {
  return _date.toISOString().slice(0, -8) + ':00.000Z'
}

export const formatDateWithTime = (_date: Date) => {
  const date = dayjs(getUtcDateString(_date)).utc().local()
  return getIntlDateWithTimeFormat(date.toDate())
}

export const formatDate = (_date: Date) => {
  const date = dayjs(getUtcDateString(_date)).utc().local()
  return getIntlDateShortFormat(date.toDate())
}

export const formatTime = (_date: Date) => {
  const date = dayjs(getUtcDateString(_date)).utc().local()
  return getIntlTimeShortFormat(date.toDate())
}

export const formatAdvancedDate = (_t: Translate, _date: Date) => {
  const now = dayjs(new Date()).local()
  const date = dayjs(getUtcDateString(_date)).utc().local()
  
  if (date.get('month') === now.get('month') && date.get('year') === now.get('year')) {
    if (date.get('date') === now.get('date')) {
      return `${_t('common:Date.todayAt')} ${formatTime(_date)}`
    }
    if (date.get('date') === now.get('date') + 1) {
      return `${_t('common:Date.tomorrowAt')} ${formatTime(_date)}`
    }
  }

  return `${formatDate(_date)} ${_t('common:Date.at')} ${formatTime(_date)}`
}

export const getDatesForDuration = (duration: 'today' | '7days' | 'all'): [startDate: string, endDate: string] => {
  let startDate = dayjs().startOf('day')
  let endDate = dayjs()

  switch (duration) {
    case 'today':
      endDate = endDate.endOf('day')
      break
    case '7days':
      endDate = endDate.add(7, 'days').endOf('day')
      break
    case 'all':
      endDate = endDate.add(1000, 'years').endOf('day')
      break
  }

  return [getIsoDateString(startDate.toDate()), getIsoDateString(endDate.toDate())]
}

export const range = function* (start: number, end?: number, step: number = 1) {
  if (end === undefined) [end, start] = [start, 0]
  for (let n = start; n <= end; n += step) {
    let s = n.toString()
    if (n < 10) s = 0 + s
    yield s
  }
}
