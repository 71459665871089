import { motion, useReducedMotion } from 'framer-motion'
import { ReactNode } from 'react'
import { FadeInOut } from '../../animations/common'

type OverlayBackgroundProps = {
  onClick?: (...args) => any
}
const OverlayBackground = ({ onClick = null }: OverlayBackgroundProps) => {
  const shouldReduceMotion = useReducedMotion()

  return (
    <motion.div
      className="overlay-background"
      variants={!shouldReduceMotion ? FadeInOut : null}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div
        className="overlay-background-inner fixed top-0 left-0 h-full w-full bg-gray-500 bg-opacity-50"
        onClick={onClick}
      ></div>
    </motion.div>
  )
}

type OverlayContainerProps = {
  children?: ReactNode
  onBackgroundClick?: (...args) => any
  className?: string
}
const OverlayContainer = ({ children = null, onBackgroundClick = null, className = '' }: OverlayContainerProps) => {
  return (
    <div
      className={`overlay-container flex flex-col justify-center items-center fixed top-0 left-0 bottom-0 right-0 h-screen h-screen-ios w-screen z-20 ${className}`}
    >
      <OverlayBackground onClick={onBackgroundClick} />
      {children}
    </div>
  )
}

export default OverlayContainer
