import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { SlideBottomInOut } from '../../animations/common'
import OverlayContainer from './OverlayContainer'

const BottomSheet = ({ onClose, children = null, maxWidth = '500px', title = null, addCloseIcon = true }) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const content = (
    <OverlayContainer onBackgroundClick={onClose}>
      <motion.div
        variants={SlideBottomInOut}
        initial="initial"
        animate="animate"
        exit="exit"
        className="bottom-sheet flex flex-col fixed bottom-0 max-h-[75vh] w-full z-[25] rounded-t-xl bg-background text-backgroundContrast overflow-hidden"
        style={{ maxWidth }}
      >
        <div className="bottom-sheet-inner flex flex-col whitespace-normal normal-case font-normal overflow-hidden relative">
          <div className="bottom-sheet-header flex justify-between items-start space-x-2 px-4 pr-6 pt-6 pb-4 border-b-[1px] border-neutral cursor-pointer" onClick={onClose}>
            <span className="bottom-sheet-header-title text-xl font-bold">{title}</span>
            {addCloseIcon && <FontAwesomeIcon className="bottom-sheet-header-close pt-1" icon={faChevronDown} />}
          </div>
          <div className="bottom-sheet-content overflow-auto p-4 pb-[max(16px,var(--safe-area-inset-bottom))]">{children}</div>
          <div className="bottom-sheet-spacer absolute bottom-0 left-0 h-[max(16px,var(--safe-area-inset-bottom))] w-full bg-gradient-to-b from-transparent to-background"></div>
        </div>
      </motion.div>
    </OverlayContainer>
  )

  if (isBrowser) {
    return (ReactDOM.createPortal(content, document.getElementById('modal-root')) as JSX.Element)
  } else {
    return null
  }
}

export default BottomSheet
