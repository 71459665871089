import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'

type ButtonProps = {
  title?: string
  children?: ReactNode
  onClick?: () => void
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  addDefaultSpacing?: boolean
  rounded?: boolean
  pulsating?: boolean
  fullWidth?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  children,
  title = null,
  onClick = null,
  className = '',
  disabled = false,
  type = 'button',
  addDefaultSpacing = true,
  rounded = false,
  pulsating = false,
  fullWidth = false,
}) => {
  return (
    <button
      onClick={onClick}
      title={title}
      className={`button flex justify-center items-center space-x-2 whitespace-nowrap text-sm font-semibold border-[1px] box-border transition-all duration-300 outline-none cursor-pointer hover:brightness-125 ${
        addDefaultSpacing ? 'px-4 py-2' : ''
      } 
      ${rounded ? 'rounded' : ''} ${pulsating ? 'animate-pulse' : ''} ${disabled ? 'opacity-50' : ''} ${
        fullWidth ? 'w-full' : ''
      } ${className}`}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  )
}

type OutlineButtonProps = ButtonProps & {
  textColor?: string
  borderColor?: string
}

export const OutlineButton: React.FC<OutlineButtonProps> = ({
  children,
  onClick = null,
  className = '',
  disabled = false,
  borderColor = 'border-primary',
  textColor = borderColor,
  type = 'button',
  rounded = false,
  fullWidth = false,
}) => {
  return (
    <Button
      onClick={onClick}
      className={`outline-button bg-transparent ${borderColor} ${textColor} ${className}`}
      disabled={disabled}
      type={type}
      rounded={rounded}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  )
}

type ContainedButtonProps = ButtonProps & {
  textColor?: string
  backgroundColor?: string
  borderColor?: string
}

export const ContainedButton: React.FC<ContainedButtonProps> = ({
  children,
  onClick = null,
  className = '',
  disabled = false,
  textColor = 'text-primaryContrast',
  backgroundColor = 'bg-primary',
  borderColor = 'border-transparent',
  type = 'button',
  rounded = false,
  pulsating = false,
  fullWidth = false,
}) => {
  return (
    <Button
      onClick={onClick}
      className={`contained-button ${backgroundColor} ${textColor} ${borderColor} ${className}`}
      disabled={disabled}
      type={type}
      rounded={rounded}
      pulsating={pulsating}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  )
}

type IconButtonProps = ButtonProps & {
  icon: IconProp
  textColor?: string
  backgroundColor?: string
  borderColor?: string
  hidden?: boolean
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  title = null,
  onClick = null,
  className = '',
  hidden = false,
  size = 'md',
  backgroundColor = 'bg-primary',
  textColor = 'text-white',
  borderColor = 'border-none',
  rounded = false,
  disabled = false,
}) => {
  let _size =
    size === 'xxs'
      ? 'w-5 h-5'
      : size === 'xs'
        ? 'w-7 h-7'
        : size === 'sm'
          ? 'w-8 h-8'
          : size === 'md'
            ? 'w-12 h-12'
            : 'w-16 h-16'

  return (
    <div className={`icon-button-wrapper flex ${hidden ? 'invisible pointer-events-none' : ''} ${className}`}>
      {
        <Button
          onClick={onClick}
          title={title}
          addDefaultSpacing={false}
          disabled={disabled}
          className={`icon-button ${
            rounded ? 'rounded-full' : ''
          } ${_size} ${backgroundColor} ${borderColor} ${textColor}`}
        >
          <FontAwesomeIcon icon={icon} className="icon-button-icon" />
        </Button>
      }
    </div>
  )
}

type BackButtonProps = {
  url?: string
  _onClick?: (...args) => any
  color?: string
  children?: any
}

export const BackButton: React.FC<BackButtonProps> = ({
  url = '',
  _onClick = null,
  color = 'text-backgroundContrast',
  children = null,
}) => {
  const router = useRouter()

  const onClick = () => {
    if (_onClick) {
      _onClick()
    } else {
      if (url) router.push(url)
      else router.back()
    }
  }

  return (
    <div className={`back-button flex items-center font-medium ${color} cursor-pointer`} onClick={onClick}>
      <IconButton
        icon={faChevronLeft}
        size={'xs'}
        className="text-base"
        backgroundColor={'bg-transparent'}
        textColor={color}
      />
      {children}
    </div>
  )
}
